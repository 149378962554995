@import url('https://fonts.googleapis.com/css2?family=Zain:ital,wght@0,200;0,300;0,400;0,700;0,800;0,900;1,300;1,400&display=swap');
/* .logo
{
    margin-top: 5%;
    display: block;
    width: 15%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
} */
.home * {
    font-family: "Zain", sans-serif;
}
.home{
    margin: auto;
    max-width: 800px;
}
h1{
    text-align: start !important;
    padding-top: 10% !important;
    font-weight: 600;
    font-size: 40px !important;
}

p{
    text-align: start !important;
    margin-left:10%;
    margin-right:10%;
    font-size: 22px !important;
}

.logos {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
}

@media (max-width: 800px) {
    .home {
        margin-left: 20px; /* Adjust as needed */
        margin-right: 20px; /* Optional, if you want symmetry */
    }
}
