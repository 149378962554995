body{
    /* background-image: linear-gradient(DodgerBlue, Aquamarine); */
    background-color: rgb(239,239,239) !important;
}

/* .navbar-brand{
    color:white !important;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  } */

.main-menu{
    color:white !important;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    
  }